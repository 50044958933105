/* Basic and common styles applied to a variety of components */

/* Sizing */

.full-width {
  width: 100%;
}

.third-width {
  width: 33.3%;
}

/* Background */

.success-bg {
  background-color: @success-color;
}

.primary-bg {
  background-color: @primary-color;
}

/* Borders */

.rounded-borders {
  .rounded-borders(50%);
}
