/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/*
  Custom variables definitions
*/
/* Spacing */
/* Colors */
/* Shadows */
/*
  Mixin definitions
*/
/* Sizing */
/* Spacing */
/* Borders */
/* Basic and common styles applied to a variety of components */
/* Sizing */
.full-width {
  width: 100%;
}
.third-width {
  width: 33.3%;
}
/* Background */
.success-bg {
  background-color: #52c41a;
}
.primary-bg {
  background-color: #1890ff;
}
/* Borders */
.rounded-borders {
  border-radius: 50%;
}
/* Style definitions for common components like buttons, inputs, tables */
/* Ant classes */
.ant-dropdown-trigger {
  height: auto;
}
/* Custom classes */
.icon-success-lg {
  color: #52c41a;
  font-size: 60px;
}
/* Icon types */
.icon-primary {
  color: #1890ff;
}
.icon-success {
  color: #52c41a;
}
.icon-danger {
  color: #ff4d4f;
}
/* Icon sizes */
.icon-large {
  font-size: 60px;
}
.main-layout {
  height: 100vh;
}
.main-layout-header {
  padding: 0 30px !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}
.main-layout-content {
  min-height: auto !important;
  padding: 30px;
}
.sidebar-menu-item-icon-wrapper {
  display: -webkit-flex-inline;
  display: -ms-flex-inlinebox;
  display: -ms-flex-inline;
  display: flex-inline;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  width: 20px;
  margin-right: 10px;
}
.layout-background {
  background-color: #e6e6e6;
  min-height: 100vh;
  height: auto !important;
}
.ant-select,
.ant-input-group {
  text-align: left;
}
.modal-form-wrapper {
  margin: 40px;
  padding: 40px;
  background-color: #fff;
  width: 360px;
  text-align: center;
}
@media screen and (max-width: 480px) {
  .modal-form-wrapper {
    margin: 10%;
    padding: 10%;
    width: 80%;
  }
}
.modal-form-wrapper-large {
  margin: 40px;
  padding: 40px;
  background-color: #fff;
  width: 540px;
  text-align: center;
}
@media screen and (max-width: 480px) {
  .modal-form-wrapper-large {
    margin: 10%;
    padding: 10%;
    width: 80%;
  }
}
.modal-form-button {
  width: 100%;
}
.third-width-input {
  width: 33.3% !important;
}
.ant-form-item-label > label {
  padding-right: 4px;
  margin-right: 16px;
}
.ant-form-item-required::before {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  transform: translateX(150%);
}
.ant-form-item-required::after,
.ant-form-item-label > label::after {
  display: none;
}
.org-select {
  line-height: normal;
  display: inline-block;
}
.org-select.has-border {
  margin-right: 10px;
  border-right: thin solid #e8e8e8;
}
.org-select .select-wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
  display: inline-block;
}
.org-select .select-wrapper .select2-container {
  min-width: 300px;
  width: 100% !important;
}
.org-select .select-wrapper .select2-container--default .select2-selection--single {
  border: none;
  outline: none;
}
.org-select .select-wrapper .select2-container--default .select2-selection--single:hover,
.org-select .select-wrapper .select2-container--default .select2-selection--single:focus,
.org-select .select-wrapper .select2-container--default .select2-selection--single:active {
  outline: none;
}
.org-select .select-wrapper .select2-container--default .select2-selection--single .select2-selection__rendered {
  font-size: 24px;
}
.org-select .select-wrapper .select2-container--default .select2-selection--single .select2-selection__rendered:hover,
.org-select .select-wrapper .select2-container--default .select2-selection--single .select2-selection__rendered:focus,
.org-select .select-wrapper .select2-container--default .select2-selection--single .select2-selection__rendered:active {
  outline: none;
}
.org-select .select-wrapper .select2-container--focus,
.org-select .select-wrapper .select2-container--focus:hover,
.org-select .select-wrapper .select2-container--focus:focus,
.org-select .select-wrapper .select2-container--focus:active {
  outline: none;
}
.org-select .select-wrapper .select2-container--default .select2-selection--multiple {
  border: thin solid #d9d9d9;
  border-radius: 0;
}
.org-select .select-wrapper .selection {
  line-height: 1.5;
}
.org-select .select-wrapper .select2-container--default .select2-selection--multiple .select2-selection__choice {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 0;
}
.org-select .select-wrapper .select2-search__field::placeholder {
  color: #bfbfbf;
}
.org-select .select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #e6f7ff;
  color: rgba(0, 0, 0, 0.65);
  font-weight: bold;
}
.org-select .select2-container--default .select2-results__option[aria-selected=true]:hover,
.org-select .select2-container--default .select2-results__option[aria-selected=true]:focus,
.org-select .select2-container--default .select2-results__option[aria-selected=true]:active {
  background-color: #e6f7ff;
  color: rgba(0, 0, 0, 0.65);
}
.org-select .select2-results {
  position: relative;
}
#org-select-dropdown .select2-dropdown {
  border-radius: 0 !important;
  border: thin solid #d9d9d9;
  width: 300px !important;
}
#org-select-dropdown .select2-container--default .select2-search--dropdown .select2-search__field:hover,
#org-select-dropdown .select2-container--default .select2-search--dropdown .select2-search__field:focus,
#org-select-dropdown .select2-container--default .select2-search--dropdown .select2-search__field:active {
  outline: none;
  border: thin solid #47a9ff;
}
#org-select-dropdown .select2-results__options .select2-results__option:not(.select2-results__message):not(.loading-results):first-of-type {
  color: #1f8efe;
  font-weight: bold;
  border-bottom: thin solid #e8e8e8;
}
#org-select-dropdown .select2-results__options .select2-results__option:not(.select2-results__message):not(.loading-results):first-of-type.select2-results__option--highlighted[aria-selected] {
  background-color: #e6f7ff;
  color: rgba(0, 0, 0, 0.65);
}
.page-section {
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}
.page-section:not(:last-child) {
  margin-bottom: 30px;
}
.page-header-extra-container > *:not(:first-child) {
  margin-left: 20px;
}
.message-page-wrapper {
  min-height: 100vh;
  min-width: 100vw;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.message-page-wrapper .message-page-inner-container {
  width: 600px;
  text-align: center;
  margin: 20px;
  padding: 20px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.message-page-wrapper .error-page-inner-container {
  margin: 20px;
  padding: 20px;
}
@media screen and (max-width: 576px) {
  .message-page-wrapper .error-page-inner-container {
    text-align: center;
  }
}
.message-page-wrapper .error-page-inner-container .error-page-title {
  font-size: 72px;
}
@media screen and (max-width: 576px) {
  .message-page-wrapper .error-page-inner-container .error-page-title {
    font-size: 52px;
  }
}
.message-page-wrapper .error-page-inner-container .error-page-description {
  font-size: 20px;
}
@media screen and (max-width: 576px) {
  .message-page-wrapper .error-page-inner-container .error-page-description {
    font-size: 18px;
  }
}
.dropdown-select-icon {
  color: #1890ff;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.dropdown-select-icon-active {
  transform: rotateX(180deg);
}
.dropdown-select-wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.dropdown-select-wrapper:hover .dropdown-select-icon {
  color: #007cee;
}
.dropdown-select-inner {
  margin: 0 10px;
  padding: 0 10px;
  cursor: pointer;
}
.dropdown-select-title {
  margin: 0 !important;
  padding: 0 !important;
}
.ant-modal-body {
  margin: 0;
  padding: 40px;
}
.selectable-rows .ant-table-tbody tr {
  cursor: pointer;
}
.m-10 {
  margin: 10px;
}
.mb-0 {
  margin-bottom: 0 !important;
}
/* Style definitions to specific components */
.profile-avatar-upload {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  position: relative;
  transition: all 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  cursor: pointer;
}
.profile-avatar-upload:hover {
  filter: brightness(90%);
}
.profile-avatar-upload-text {
  border-radius: 50%;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 24px;
  color: #fff;
}
.profile-avatar-upload:hover .profile-avatar-upload-text {
  display: inline;
}
.profile-avatar-upload .profile-avatar-img {
  width: 160px;
  height: 160px;
  border-radius: 50%;
}
.profile-user-status-text {
  margin: 0;
  padding: 0 10px;
  color: #fff !important;
  border-radius: 20px;
}
.profile-mobile-input-icon {
  position: absolute;
  right: 1rem;
  bottom: 0;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.users-status-wrapper {
  width: 160px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.users-status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}
.users-status-dot-enabled {
  background-color: #52c41a;
}
.users-status-dot-onboarding {
  background-color: #faad14;
}
.users-status-dot-archived {
  background-color: #d9d9d9;
}
.org-select-wrapper {
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
.org-select-input-wrapper {
  margin: 0;
  padding: 4px;
}
.org-select-divider {
  margin: 0 !important;
  padding: 0 !important;
}
.org-select-list-wrapper {
  width: 100%;
  min-width: 360px;
  max-height: 360px;
  overflow: auto;
}
.org-select-list-item {
  margin: 0 !important;
  padding: 10px !important;
  cursor: pointer;
}
.org-select-list-item-selected {
  background-color: #cbe6ff;
}
.org-select-list-item:hover {
  background-color: #f5f5f5;
}
.google-map-container,
.google-map {
  width: 100%;
  height: 100%;
}
.payment-checkout-title-wrapper {
  padding: 10px;
}
.payment-checkout-title-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 10px;
}
.payment-checkout-card-icon {
  width: 40px;
  display: inline-block;
}
.section-wrapper-test-case {
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  height: calc(100vh - 100px);
  padding: 30px;
  z-index: 2;
}
@media only screen and (max-width: 1200px) {
  .section-wrapper-test-case {
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    height: calc(100vh - 70px);
    padding: 30px;
    z-index: 2;
  }
}
@media only screen and (max-width: 1024px) {
  .section-wrapper-test-case {
    height: calc(100vh - 100px);
  }
}
@media only screen and (max-width: 480px) {
  .section-wrapper-test-case {
    height: calc(100vh - 70px);
  }
}
.section-wrapper {
  background-color: #fff;
  height: calc(100vh - 100px);
  padding: 30px;
}
.section-mobile-wrapper {
  background-color: #fff;
  height: calc(100vh - 70px);
  padding: 30px;
}
.section-header {
  border-bottom: 1px solid lightgray;
}
.test-note-editor {
  width: 100%;
  margin: 20px auto;
}
.test-note-editor .ql-editor {
  min-height: calc(100vh - 380px);
}
.test-note-editor .ql-container.ql-snow {
  border: none;
  border-bottom: 2px solid #5cdbf0;
}
.test-note-editor .ql-toolbar.ql-snow {
  border: none;
  border-bottom: 2px solid #5cdbf0;
}
.test-note-editor-dirty {
  width: 100%;
  margin: 20px auto;
}
.test-note-editor-dirty .ql-editor {
  min-height: calc(100vh - 380px);
}
.test-note-editor-dirty .ql-container.ql-snow {
  border: none;
  border-bottom: 2px solid #faad14;
}
.test-note-editor-dirty .ql-toolbar.ql-snow {
  border: none;
  border-bottom: 2px solid #faad14;
}
.test-note-text {
  margin-bottom: 0.5em;
}
.drag-and-drop-table {
  margin: 2rem 0;
}
.drag-and-drop-row {
  padding: 0.5rem 0;
  border-bottom: 1px solid lightgray;
}
.drag-and-drop-row .ant-select .ant-select-selector {
  border: none !important;
}
.drag-and-drop-row span.ant-select-arrow {
  color: #5cdbf0 !important;
}
.test-scenario-wrapper {
  height: calc(100vh - 250px);
}
.test-page-action {
  min-width: 9rem;
  margin-top: 1rem;
}
.test-page-button-group {
  position: absolute;
  bottom: 10px;
}
.available-test-case-table.ant-table-wrapper {
  width: 100%;
}
.available-test-case-table .ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: white !important;
}
.available-test-case-table .ant-table-thead > tr > th {
  background-color: white;
  border-bottom: 2px solid #5cdbf0;
}
.available-test-case-table .ant-table-thead > tr > th::before {
  display: none;
}
.available-test-case-table .ant-select .ant-select-selector {
  border: none !important;
  background-color: transparent !important;
}
.available-test-case-table span.ant-select-arrow {
  color: #5cdbf0 !important;
}
.test-menu-search {
  margin-top: 0px;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.75) !important;
}
.test-menu-search .ant-input {
  background-color: transparent !important;
}
.test-menu-search .anticon-search svg {
  width: 1.5rem;
  height: 1.5rem;
  color: lightgrey;
}
.test-menu-search.ant-input-affix-wrapper {
  border: unset !important;
}
.test-menu-tab .ant-tabs-ink-bar {
  display: none;
}
.test-menu-tab .ant-tabs-tab-btn {
  margin: auto;
  color: #001a45;
}
.test-menu-tab .ant-tabs-tab-active {
  background-color: #001a45;
}
.test-menu-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
}
.test-menu-tab .ant-tabs-nav-wrap {
  overflow-x: hidden;
}
.test-menu-tab .ant-tabs-tab {
  padding: 0px;
  margin: 0;
}
.test-menu-tab .ant-tabs-tab-btn {
  text-align: center;
  cursor: pointer;
}
.test-menu-tab .ant-tabs-tab:nth-child(1) {
  width: 65%;
}
.test-menu-tab .ant-tabs-tab:nth-child(2) {
  width: 35%;
}
.test-menu-tab .ant-tabs-nav {
  height: 1.5rem;
}
.test-menu-tab .ant-tabs-nav-list {
  border: 1px solid #001a45;
  width: 100%;
  display: flex;
}
.test-menu-tab .ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}
.ant-tabs-content-holder {
  padding: 10px 0px;
}
.tests-menu-item-container {
  display: flex;
  height: auto;
  width: 100%;
  align-items: center;
  padding: 0px 10px;
  background-color: unset;
}
.tests-menu-item-container.white-bg {
  background-color: #fff;
}
.tests-menu-item-container.blue-bg {
  background-color: rgba(92, 219, 240, 0.35);
}
.tests-menu-item {
  display: block;
  line-height: 28px;
  font-size: 14px;
  color: #001a45;
  font-weight: 400;
}
.tests-menu-item.selected {
  font-weight: 600;
}
.tests-menu-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tests-menu-header {
  font-size: 16px;
  color: #001a45;
  font-weight: 600;
}
.tests-menu-header-lv2 {
  font-size: 14px;
  color: #001a45;
  font-weight: 600;
}
.tests-menu-header-bottom-line {
  width: 100%;
  height: 2px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.tests-menu-header-bottom-line.blue-bg {
  background-color: #5cdbf0;
}
.tests-menu-header-bottom-line.white-bg {
  background-color: #ffffff;
}
.tests-product-menu {
  margin-bottom: 60px;
}
.tests-suite-menu {
  margin-bottom: 20px;
}
.test-detail-modal .ant-modal-header {
  display: none;
}
.test-detail-modal .ant-modal-footer {
  display: none;
}
.test-detail-modal .ant-modal-close {
  display: none;
}
.test-detail-modal .ant-modal-body {
  background-color: #f0f2f5;
  padding: 0px;
}
.test-detail-modal .test-step-menu-wrapper {
  height: 100%;
  padding: 20px;
}
.test-detail-modal-button {
  border-top: 1px solid lightgrey;
}
.test-detail-modal-test-menu {
  height: calc(100vh - 325px);
}
.mobile-button-group {
  border-top: 1px solid lightgrey;
  position: fixed;
  bottom: 20px;
}
.table-mobile-wrapper {
  margin: 0 -20px;
}
.tests-product-menu-ipad,
.tests-release-menu-ipad {
  margin: 10px 0px 30px 0px;
}
.tests-product-menu-ipad .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.tests-release-menu-ipad .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: unset;
}
.tests-form-modal {
  top: 30%;
}
.tests-form-modal .ant-modal-header {
  display: none;
}
.tests-form-modal .ant-modal-body {
  padding: 0px;
}
.tests-form-modal .ant-modal-footer {
  display: none;
}
.tests-form-modal .ant-form {
  padding: 0px 40px 40px 40px;
}
.tests-form-modal .ant-form-item {
  margin-bottom: unset;
}
.tests-form-modal .ant-form-item-control-input-content input,
.tests-form-modal .ant-form-item-control-input-content textarea {
  border-top: none;
  border-right: none;
  border-left: none;
}
.tests-form-modal .ant-form-item-label {
  position: absolute;
  z-index: 1;
}
.tests-form-modal .ant-form-item-control {
  margin: 25px 0px 10px 0px;
}
.tests-form-modal .ant-form-item-control-input-content {
  display: flex;
  justify-content: center;
}
.tests-form-modal .ant-modal-close {
  display: none;
}
.tests-form-modal .tests-form-btn {
  margin: 0px 10px;
}
.tests-form-modal .tests-form-btn-close {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  margin: 14px 10px;
}
.tests-form-modal h3 {
  margin: 20px;
  text-align: center;
}
.tests-form-modal-header {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background-color: #001a45;
}
.tests-form-modal-header .logo-device {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 10px;
  left: 10px;
}
.tests-form-modal-header-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  padding: 10px;
}
.tests-form-modal-header-text-container span {
  color: white;
  font-size: 26px;
  letter-spacing: 1px;
}
.tests-form-modal-header-bottom-line {
  width: 100%;
  height: 5px;
  background-image: linear-gradient(to right, #78ff00, #5cdbf0);
}
.tests-form-modal-header-logo {
  display: flex;
  width: 40px;
  height: 40px;
  position: absolute;
  margin: 10px;
}
.button-wrapper {
  position: fixed;
  bottom: 40px;
  right: 10px;
  left: 10px;
  border-top: 1px solid lightgrey;
}
