
.page-section {
  padding: @layout-spacing;
  background-color: @page-section-bg-color;
  box-shadow: @box-shadow-1;

  &:not(:last-child) {
    margin-bottom: @layout-spacing;
  }
}

.page-header-extra-container {
  & > *:not(:first-child) {
    margin-left: @spacing-2;
  }
}

.message-page-wrapper {
  min-height: 100vh;
  min-width: 100vw;

  .flex-display();
  .justify-content(center);
  .align-items(center);

  .message-page-inner-container {
    width: 600px;
    text-align: center;

    .spacing(@spacing-2, @spacing-2);

    .flex-display();
    .justify-content(center);
    .align-items(center);
    .flex-direction(column);
  }

  .error-page-inner-container {
    .spacing(@spacing-2, @spacing-2);

    @media screen and (max-width: @screen-sm) {
      text-align: center;
    }

    .error-page-title {
      font-size: 72px;

      @media screen and (max-width: @screen-sm) {
        font-size: 52px;
      }
    }

    .error-page-description {
      font-size: 20px;

      @media screen and (max-width: @screen-sm) {
        font-size: 18px;
      }
    }
  }
}
