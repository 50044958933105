.icon-success-lg {
  .icon-success;
  .icon-large;
}

/* Icon types */

.icon-primary {
  color: @primary-color;
}

.icon-success {
  color: @success-color;
}

.icon-danger {
  color: @error-color;
}

/* Icon sizes */

.icon-large {
  font-size: 60px;
}
