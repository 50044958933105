.section-wrapper-test-case {
    background-color: #fff;
    box-shadow: 0 5px 10px rgb(0 0 0 / 15%);
    height: calc(100vh - 100px);
    padding: 30px;
    z-index: 2;
  }
  
  @media only screen and (max-width: 1200px) {
    .section-wrapper-test-case {
      background-color: #fff;
      box-shadow: 0 5px 10px rgb(0 0 0 / 15%);
      height: calc(100vh - 70px);
      padding: 30px;
      z-index: 2;
    }
  }

  @media only screen and (max-width: 1024px) {
    .section-wrapper-test-case {
      height: calc(100vh - 100px);
    }
  }

  @media only screen and (max-width: 480px) {
    .section-wrapper-test-case {
      height: calc(100vh - 70px);
    }
  }

  .section-wrapper {
    background-color: #fff;
    height: calc(100vh - 100px);
    padding: 30px;
  }

  .section-mobile-wrapper{
    background-color: #fff;
    height: calc(100vh - 70px);
    padding: 30px;
  }
  
  .section-header {
    border-bottom: 1px solid lightgray;
  }
  
  .test-note {
    &-editor {
      width: 100%;
      margin: 20px auto;
  
      .ql-editor {
        min-height: calc(100vh - 380px);
      }
  
      .ql-container.ql-snow {
        border: none;
        border-bottom: 2px solid #5cdbf0;
      }
  
      .ql-toolbar.ql-snow {
        border: none;
        border-bottom: 2px solid #5cdbf0;
      }
    }
  
    &-editor-dirty {
      width: 100%;
      margin: 20px auto;
  
      .ql-editor {
        min-height: calc(100vh - 380px);
      }
  
      .ql-container.ql-snow {
        border: none;
        border-bottom: 2px solid #faad14;
      }
  
      .ql-toolbar.ql-snow {
        border: none;
        border-bottom: 2px solid #faad14;
      }
    }
  
    &-text {
      margin-bottom: 0.5em;
    }
  }
  
  .drag-and-drop {
    &-table {
      margin: 2rem 0;
    }
  
    &-row {
      padding: 0.5rem 0;
      border-bottom: 1px solid lightgray;
  
      .ant-select .ant-select-selector {
        border: none !important;
      }
  
      span.ant-select-arrow {
        color: #5cdbf0 !important;
      }
    }
  }
  
  .test {
    &-scenario-wrapper {
      height: calc(100vh - 250px);
    }
  }
  
  .test-page-action {
    min-width: 9rem;
    margin-top: 1rem;
  }
  
  .test-page-button-group {
    position: absolute;
    bottom: 10px;
  }
  
  .available-test-case-table {
    &.ant-table-wrapper {
      width: 100%;
    }
    .ant-table-tbody > tr.ant-table-row-selected > td {
      background-color: white !important;
    }
  
    .ant-table-thead > tr > th {
      background-color: white;
      border-bottom: 2px solid #5cdbf0;
    }
  
    .ant-table-thead > tr > th::before {
      display: none;
    }
  
    .ant-select .ant-select-selector {
      border: none !important;
      background-color: transparent !important;
    }
  
    span.ant-select-arrow {
      color: #5cdbf0 !important;
    }
  }
  
  .test-menu-search {
    margin-top: 0px;
    margin-bottom: 20px;
    background-color: rgba(255, 255, 255, 0.75) !important;
    .ant-input {
      background-color: transparent !important;
    }
    .anticon-search {
      svg {
        width: 1.5rem;
        height: 1.5rem;
        color: lightgrey;
      }
    }
    &.ant-input-affix-wrapper {
      border: unset !important;
    }
  }
  
  .test-menu-tab {
    .ant-tabs-ink-bar {
      display: none;
    }
  
    .ant-tabs-tab-btn {
      margin: auto;
      color: #001a45;
    }
  
    .ant-tabs-tab-active {
      background-color: #001a45;
      .ant-tabs-tab-btn {
        color: white !important;
      }
    }
  
    .ant-tabs-nav-wrap {
      overflow-x: hidden;
    }
  
    .ant-tabs-tab {
      padding: 0px;
      margin: 0;
      &-btn {
        text-align: center;
        cursor: pointer;
      }
    }
  
    .ant-tabs-tab:nth-child(1) {
      width: 65%;
    }
  
    .ant-tabs-tab:nth-child(2) {
      width: 35%;
    }
  
    .ant-tabs-nav {
      height: 1.5rem;
    }
  
    .ant-tabs-nav-list {
      border: 1px solid #001a45;
      width: 100%;
      display: flex;
    }
  
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0;
    }
  }

  .ant-tabs-content-holder {
    padding: 10px 0px;
  }
  
  .tests-menu-item-container {
    display: flex;
    height: auto;
    width: 100%;
    align-items: center;
    padding: 0px 10px;
    background-color: unset;
  }
  
  .tests-menu-item-container.white-bg {
    background-color: #fff;
  }
  
  .tests-menu-item-container.blue-bg {
    background-color: rgba(92, 219, 240, 0.35);
  }
  
  .tests-menu-item {
    display: block;
    line-height: 28px;
    font-size: 14px;
    color: #001a45;
    font-weight: 400;
    &.selected {
      font-weight: 600;
    }
  }
  
  .tests-menu-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .tests-menu-header {
    font-size: 16px;
    color: #001a45;
    font-weight: 600;
  }
  
  .tests-menu-header-lv2 {
    font-size: 14px;
    color: #001a45;
    font-weight: 600;
  }
  
  .tests-menu-header-bottom-line {
    width: 100%;
    height: 2px;
    margin-top: 10px;
    margin-bottom: 20px;
    &.blue-bg {
      background-color: #5cdbf0;
    }
    &.white-bg {
      background-color: #ffffff;
    }
  }
  
  .tests-product-menu {
    margin-bottom: 60px;
  }
  
  .tests-suite-menu {
    margin-bottom: 20px;
  }
  
  .test-detail-modal {
    .ant-modal-header {
      display: none;
    }
  
    .ant-modal-footer {
      display: none;
    }
  
    .ant-modal-close {
      display: none;
    }
  
    .ant-modal-body {
      background-color: #f0f2f5;
      padding: 0px;
    }
  
    .test-step-menu-wrapper {
      height: 100%;
      padding: 20px;
    }
  
    &-button {
      border-top: 1px solid lightgrey;
    }
  
    &-test-menu {
      height: calc(100vh - 325px);
    }
  }
  
  .mobile-button-group {
    border-top: 1px solid lightgrey;
    position: fixed;
    bottom: 20px;
  }
  
  .table-mobile-wrapper {
    margin: 0 -20px;
  }
  
  .tests-product-menu-ipad,
  .tests-release-menu-ipad {
    margin: 10px 0px 30px 0px;
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: unset;
    }
  }
  
  .tests-form-modal {
    top: 30%;
    .ant-modal-header {
      display: none;
    }
    .ant-modal-body {
      padding: 0px;
    }
    .ant-modal-footer {
      display: none;
    }
    .ant-form {
      padding: 0px 40px 40px 40px;
      &-item {
        margin-bottom: unset;
        &-control-input-content {
          input,
          textarea {
            border-top: none;
            border-right: none;
            border-left: none;
          }
        }
        &-label {
          position: absolute;
          z-index: 1;
        }
        &-control {
          margin: 25px 0px 10px 0px;
          &-input-content {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
    .ant-modal-close {
      display: none;
    }
    .tests-form-btn {
      margin: 0px 10px;
      &-close {
        position: absolute;
        top: 0;
        right: 0;
        color: white;
        margin: 14px 10px;
      }
    }
    h3 {
      margin: 20px;
      text-align: center;
    }
  }
  
  .tests-form-modal-header {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    background-color: #001a45;
    .logo-device {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 10px;
      left: 10px;
    }
    &-text-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
      padding: 10px;
      span {
        color: white;
        font-size: 26px;
        letter-spacing: 1px;
      }
    }
    &-bottom-line {
      width: 100%;
      height: 5px;
      background-image: linear-gradient(to right, #78ff00, #5cdbf0);
    }
    &-logo {
      display: flex;
      width: 40px;
      height: 40px;
      position: absolute;
      margin: 10px;
    }
  }
  
.button-wrapper {
  position: fixed;
  bottom: 40px;
  right: 10px;
  left: 10px;
  border-top: 1px solid lightgrey;
}