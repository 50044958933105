/*
  Mixin definitions
*/

/* Sizing */

.size(@width, @height) {
  width: @width;
  height: @height;
}

.size(@value) {
  .size(@value, @value);
}

/* Spacing */

.spacing(@margin, @padding) {
  margin: @margin;
  padding: @padding;
}

/* Borders */

.rounded-borders(@value) {
  border-radius: @value;
}

.space-for-items-right(@margin) {
  margin-right: @margin;

  &:last-child {
    margin-right: 0;
  }
}

.space-for-items-bottom(@margin) {
  margin-bottom: @margin;

  &:last-child {
    margin-right: 0;
  }
}

.padding-horizontal(@value) {
  padding-left: @value;
  padding-right: @value;
}

.padding-horizontal-desktop-mobile(@valueDesktop, @valueMobile) {
  .padding-horizontal(@valueDesktop);

  @media (max-width: @screen-lg-max) {
    .padding-horizontal(@valueMobile);
  }
}

.margin-horizontal(@value) {
  margin-left: @value;
  margin-right: @value;
}
