.payment {
  &-checkout {
    &-title {
      &-wrapper {
        padding: @spacing-1;
      }

      &-container {
        .flex-display();
        .justify-content(space-between);
        .align-items(center);

        margin-bottom: @spacing-1;
      }
    }

    &-card-icon {
      width: 40px;
      display: inline-block;
    }
  }
}
