.users-status {
  &-wrapper {
    width: 160px;
    .flex-display();
    .align-items(center);
  }

  &-dot {
    display: inline-block;
    .size(10px);
    .rounded-borders();

    margin-right: 5px;

    &-enabled {
      background-color: @status-enabled-color;
    }

    &-onboarding {
      background-color: @status-onboarding-color;
    }

    &-archived {
      background-color: @status-archived-color;
    }
  }
}
