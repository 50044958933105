.dropdown-select {
  &-icon {
    color: @primary-color;
    transition: all @animation-duration-slow @ease-in-out;

    &-active {
      transform: rotateX(180deg);
    }
  }

  &-wrapper {
    .flex-display();

    &:hover {
      .dropdown-select-icon {
        color: darken(@primary-color, 8%);
      }
    }
  }

  &-inner {
    .spacing(0 @spacing-1, 0 @spacing-1);

    cursor: pointer;
  }

  &-title {
    .spacing(0, 0) !important;
  }
}
