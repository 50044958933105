.ant-select,
.ant-input-group {
  text-align: left;
}

.modal-form {
  &-wrapper {
    .spacing(@spacing-4, @spacing-4);

    background-color: @white;
    width: 360px;
    text-align: center;

    @media screen and (max-width: @screen-xs-min) {
      .spacing(10%, 10%);

      width: 80%;
    }

    &-large {
      .spacing(@spacing-4, @spacing-4);

      background-color: @white;
      width: 540px;
      text-align: center;

      @media screen and (max-width: @screen-xs-min) {
        .spacing(10%, 10%);

        width: 80%;
      }
    }
  }

  &-button {
    .full-width;
  }
}

.third-width-input {
  .third-width !important;
}

.ant-form-item {
  &-label > label {
    padding-right: 4px;
    margin-right: 16px;
  }

  &-required::before {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    transform: translateX(150%);
  }

  &-required::after,
  &-label > label::after {
    display: none;
  }
}
