.org-select {
  &-wrapper {
    background-color: @white;
    border: 1px solid @normal-color;
  }

  &-input-wrapper {
    .spacing(0, 4px);
  }

  &-divider {
    .spacing(0, 0) !important;
  }

  &-list {
    &-wrapper {
      width: 100%;
      min-width: 360px;
      max-height: 360px;
      overflow: auto;
    }

    &-item {
      .spacing(0, @spacing-1) !important;

      cursor: pointer;

      &-selected {
        background-color: lighten(@primary-color, 35%);
      }

      &:hover {
        background-color: @item-hover-bg;
      }
    }
  }
}
