.org-select {
  line-height: normal;
  display: inline-block;

  &.has-border {
    margin-right: 10px;
    border-right: thin solid #e8e8e8;
  }

  .select-wrapper {
    position: relative;
    width: 100%;
    z-index: 1;
    display: inline-block;

    .select2-container {
      min-width: 300px;
      width: 100% !important;
    }

    .select2-container--default .select2-selection--single {
      border: none;
      outline: none;

      &:hover,
      &:focus,
      &:active {
        outline: none;
      }

      .select2-selection__rendered {
        font-size: 24px;

        &:hover,
        &:focus,
        &:active {
          outline: none;
        }
      }
    }

    .select2-container--focus,
    .select2-container--focus:hover,
    .select2-container--focus:focus,
    .select2-container--focus:active {
      outline: none;
    }

    .select2-container--default .select2-selection--multiple {
      border: thin solid #d9d9d9;
      border-radius: 0;
    }

    .selection {
      line-height: 1.5;
    }

    .select2-container--default .select2-selection--multiple .select2-selection__choice {
      color: rgba(0, 0, 0, 0.65);
      background-color: #fafafa;
      border: 1px solid #e8e8e8;
      border-radius: 0;
    }

    .select2-search__field::placeholder {
      color: #bfbfbf;
    }
  }

  .select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #e6f7ff;
    color: rgba(0, 0, 0, 0.65);
    font-weight: bold;
  }

  .select2-container--default .select2-results__option[aria-selected=true]:hover,
  .select2-container--default .select2-results__option[aria-selected=true]:focus,
  .select2-container--default .select2-results__option[aria-selected=true]:active {
    background-color: #e6f7ff;
    color: rgba(0, 0, 0, 0.65);
  }

  .select2-results {
    position: relative;
  }
}

#org-select-dropdown {
  .select2-dropdown {
    border-radius: 0 !important;
    border: thin solid #d9d9d9;
    width: 300px !important;
  }

  .select2-container--default .select2-search--dropdown .select2-search__field:hover,
  .select2-container--default .select2-search--dropdown .select2-search__field:focus,
  .select2-container--default .select2-search--dropdown .select2-search__field:active {
    outline: none;
    border: thin solid #47a9ff;
  }

  .select2-results__options .select2-results__option:not(.select2-results__message):not(.loading-results):first-of-type {
    color: #1f8efe;
    font-weight: bold;
    border-bottom: thin solid #e8e8e8;

    &.select2-results__option--highlighted[aria-selected] {
      background-color: #e6f7ff;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
